import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

/* UI Libraries */
import { MaterialModule } from './_commons/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Plugins */
//  notifiche
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ToastNotificationComponent } from './utility/toast-notification/toast-notification.component';
//  grafici
import { GoogleChartsModule } from 'angular-google-charts';

/* Localizzazione */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
registerLocaleData(localeIT);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/* Providers */
import { BackendService } from './services/general/backend.service';

/* Componenti Helper */
import { Scroll } from './_helpers/scroll/scroll.component';

/* Direttive */
import { TrimValueAccessorDirective } from './_directives/trim-value-accessor.directive';

/* Componenti principali */
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

/* Componenti pagine */
import { HomeComponent } from './pages/home/home.component';
import { FrameworkComponent } from './pages/framework/framework.component';

import { NewDecisionTableComponent } from './components/decision-table/new-decision-table/new-decision-table.component';
import { ShowDecisionTableComponent } from './components/decision-table/show-decision-table/show-decision-table.component';

import { ModalNewProposalComponent } from './modals/modal-new-proposal/modal-new-proposal.component';
import { NewProposalComponent } from './components/proposals/new-proposal/new-proposal.component';
import { NewProposalBlankComponent } from './components/proposals/new-proposal-blank/new-proposal-blank.component';

import { ResponsesSummaryComponent } from './pages/responses/responses-summary/responses-summary.component';
import { ResponsesWeightTargetsComponent } from './pages/responses/responses-weight-targets/responses-weight-targets.component';
import { ResponsesWeightCriteriaComponent } from './pages/responses/responses-weight-criteria/responses-weight-criteria.component';
import { ResponsesEvaluationComponent } from './pages/responses/responses-evaluation/responses-evaluation.component';
import { ResponsesProposalReviewComponent } from './pages/responses/responses-proposal-review/responses-proposal-review.component';
import { DecisionTableComponent } from './pages/decision-table/decision-table.component';
import { DecisionTableDashboardComponent } from './components/decision-table/decision-table-dashboard/decision-table-dashboard.component';
import { ModalContextDiscoveryComponent } from './modals/modal-context-discovery/modal-context-discovery.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import { TaskManagementComponent } from './pages/task-management/task-management.component';
import { ModalNetworkAnalisysComponent } from './modals/modal-network-analisys/modal-network-analisys.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    AppComponent,
    ToastNotificationComponent,
    TrimValueAccessorDirective,
    Scroll,

    HeaderComponent,
    FooterComponent,
    HomeComponent,

    FrameworkComponent,

    DecisionTableComponent,
    NewDecisionTableComponent,
    ShowDecisionTableComponent,

    ModalNewProposalComponent,
    NewProposalComponent,
    NewProposalBlankComponent,

    ResponsesSummaryComponent,
    ResponsesWeightTargetsComponent,
    ResponsesWeightCriteriaComponent,
    ResponsesEvaluationComponent,
    ResponsesProposalReviewComponent,
    DecisionTableDashboardComponent,
    ModalContextDiscoveryComponent,
    TaskManagementComponent,
    ModalNetworkAnalisysComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'it'
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    GoogleChartsModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    BackendService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
